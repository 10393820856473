import React, { useState, useEffect } from "react";
import Button from '@mui/material/Button';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import Grow from '@mui/material/Grow';
import Fade from '@mui/material/Fade';
import Zoom from '@mui/material/Zoom';
import moment from 'moment';
import 'moment/locale/es';
import 'moment-timezone';
import { color } from "@mui/system";
import CircularProgress from '@mui/material/CircularProgress';

const SliderDias = ({ horariosCoz, horariosCal }) => {
    let fecha = moment();
    let fechaActual = fecha.format('DD/MM/YYYY');
    // console.log(fechaActual)
    moment.locale('es');
    const localTimezone = moment.tz.guess();
    const [startDay, setStartDay] = useState(moment().tz(localTimezone).day(1));
    const [diaInicioG, setDiaInicioG] = useState(moment().tz(localTimezone).day(1));
    const [mes, setMes] = useState(moment().tz(localTimezone).month() + 1);
    // console.log("mes ACtual:" + mes)
    const [trancision1, setTrancision1] = useState(0);
    const [meses, setMeses] = useState([])
    const [cargando, setCargando] = useState(true);

    const prevWeek = () => {
        if (startDay >= diaInicioG) {
            setStartDay(prevStartDay => {
                const newStartDay = prevStartDay.clone().subtract(7, 'days');
                //     console.log("startDay: " + newStartDay);
                //    console.log("diaInicioG: " + diaInicioG);

                const newMonth = newStartDay.month() + 1; // Sumar 1 para que el mes sea del 1 al 12
                // console.log("new messsss: " + newMonth);
                setMes(newMonth); // Actualizar el estado del mes
                setTrancision1((prevTrancision1) => prevTrancision1 + 1);
                return newStartDay;
            });
        }
    };


    const nextWeek = () => {
        setStartDay(prevStartDay => {
            const newStartDay = prevStartDay.clone().add(7, 'days');
            const newMonth = newStartDay.month() + 1; // Sumar 1 para que el mes sea del 1 al 12
            //  console.log("NUEVO MES: " + newMonth);
            setMes(newMonth); // Actualizar el estado del mes
            setTrancision1((prevTrancision1) => prevTrancision1 + 1);
            return newStartDay;
        });
    };

    function filtrarDia(dia, fecha, data) {


        let dataDias = data.detail.map((item) => {
            const fecha_inicio = item.fecha_inicio;
            const fecha_fin = item.fecha_fin;
            const item_dia = item.dia.toLowerCase();
            const horario = item.horario.slice(0, -3)
            if (fecha >= fecha_inicio && fecha <= fecha_fin && dia == item_dia) {
                // console.log("dia: " + dia + " fecha: " + fecha_fin)
                if (item.carga_negra) {
                    return <strong className="horariosItems" style={{ color: "#ff0000", fontWeight: "900" }}>{horario} *</strong>
                } else {
                    return <strong className="horariosItems" style={{ color: "#ff0000", fontWeight: "900" }}>{horario}</strong>
                }
            } else {
                return null
            }
        })
        return dataDias
    }

    function quitarAcentos(texto) {
        return texto.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    }

    const cambiaMesEnSelect = (event: SelectChangeEvent) => {
        // Obtener el valor del mes seleccionado (1-12)
        const mesSeleccionado = parseInt(event.target.value);

        // Obtener el primer día del mes seleccionado (meses de 1 a 12)
        // Si estamos en diciembre y el usuario selecciona enero, debemos avanzar al siguiente año
        let primerDiaDelMes = moment().tz(localTimezone).month(mesSeleccionado - 1).date(1); // Ajustamos mes porque moment espera valores de 0 a 11

        // Verificar si el mes seleccionado está en el siguiente año
        if (mesSeleccionado === 1 && moment().month() === 11) { // Si es enero y estamos en diciembre
            primerDiaDelMes.add(1, 'year'); // Aumentamos el año en 1
        }

        // Ajustar al lunes más cercano si el primer día no es lunes
        if (primerDiaDelMes.day() !== 1) {
            // Si el primer día no es lunes, mover al lunes anterior
            primerDiaDelMes = primerDiaDelMes.day(1);  // Esto ajusta al lunes anterior si no es lunes
        }

        //console.log("Primer día del mes: " + primerDiaDelMes.format('YYYY-MM-DD'));
        setStartDay(primerDiaDelMes);
        setMes(mesSeleccionado);  // El valor que se pasa a setMes es el valor original (1-12)
        setTrancision1((prevTrancision1) => prevTrancision1 + 1);
    };


    useEffect(() => {

        let nuevosMeses = [...meses]; // se hace una copia de los 'meses'
        horariosCoz.detail.forEach((item) => {

            const mes = new Date(item.fecha_fin).getMonth() + 1;

            if (item.fecha_fin >= fecha.format('YYYY-MM-DD')) {
                if (!nuevosMeses.includes(mes)) {
                    //console.log("mes agregado: " + item.fecha_inicio)
                    nuevosMeses.push(mes);
                }
            }
        });

        setMeses(nuevosMeses); // Actualiza el estado una vez después del bucle
        //console.log("NUEVOS MESES: " + meses)
        setCargando(false);
    }, []);

    useEffect(() => {
        //  console.log(meses);
    }, [meses]);

    if (cargando) {
        return <CircularProgress /> // Muestra un mensaje de carga mientras el arreglo se está llenando
    }
    return (
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", gap: "1rem", backgroundColor: "white" }}>
            <div className="mainContainer">
                <div style={{ display: "flex", gap: "1rem", color: "white", flexDirection: "column" }}>
                    <div style={{ display: "flex", flexDirection: "column", gap: ".3rem", padding: "1rem" }}>

                        <Select
                            style={{ color: "#03307d", border: "solid white 1px", minWidth: "10rem", fontWeight: "bold", fontSize: "1.5em", textTransform: "uppercase" }}
                            id="demo-simple-select"
                            value={mes}
                            onChange={cambiaMesEnSelect}
                        >
                            {meses.map((mesI, index) => (
                                <MenuItem key={index} value={mesI}>
                                    {fecha.month(mesI - 1).format('MMMM').toUpperCase()}
                                </MenuItem>
                            ))}
                        </Select>

                    </div>
                </div>
                <div style={{ display: "flex" }}>
                    <Button style={{ minWidth: "1.5rem", height: "3rem", backgroundColor: "#ff0000", color: "white", borderRadius: "50px 0px 0px 50px", padding: "10px" }} variant="contained" onClick={prevWeek} endIcon={<ArrowBackIosNewIcon />}>
                    </Button>
                    <div style={{ display: "flex", color: "white" }}>
                        {Array.from({ length: 7 }, (_, i) => startDay.clone().add(i, 'days')).map((day, index) => (
                            <div key={index} className="itemFecha" style={fechaActual == day.format('DD/MM/YYYY') ? { backgroundColor: "#03307d" } : { backgroundColor: "#ff0000" }}>
                                {day.format('DD')}<br></br> {day.format('ddd').toUpperCase()}<br></br>{/*day.format('DD/MM/YYYY')*/}
                            </div>
                        ))}
                    </div>
                    <Button style={{ minWidth: "1.5rem", height: "3rem", backgroundColor: "#ff0000", color: "white", borderRadius: "0px 50px 50px 0px", padding: "10px" }} variant="contained" onClick={nextWeek} endIcon={<ArrowForwardIosIcon />}>
                    </Button>
                </div>

                <Grow in={true} key={trancision1}  {...(trancision1 ? { timeout: 1000 } : {})}>
                    <div>
                        <h3 style={{ padding: "1rem", color: "#002f87", fontWeight: "700" }}>Saliendo/Departure from</h3>
                        <h1 style={{ color: "#e72816", fontWeight: "800" }}>COZUMEL</h1>
                        <div style={{ display: "flex", color: "white" }} className="containerDias">
                            {Array.from({ length: 7 }, (_, i) => startDay.clone().add(i, 'days')).map((day, index) => (
                                <div style={{ display: "flex", flexDirection: "column", width: "14%", gap: ".3rem" }}>
                                    <strong style={{ color: "#03307d" }}> {day.format('ddd').toUpperCase()}</strong>
                                    <strong style={{ color: "#03307d" }}>HRS</strong>
                                    {filtrarDia(quitarAcentos(day.format('dddd')), day.format('YYYY-MM-DD'), horariosCoz)}
                                </div>
                            ))}
                        </div>
                        <h3 style={{ padding: "1rem", color: "#002f87", fontWeight: "700" }}>Saliendo/Departure from</h3>
                        <h1 style={{ color: "#e72816", fontWeight: "800" }}>CALICA</h1>
                        <div style={{ display: "flex", color: "white" }} className="containerDias">
                            {Array.from({ length: 7 }, (_, i) => startDay.clone().add(i, 'days')).map((day, index) => (
                                <div style={{ display: "flex", flexDirection: "column", width: "14%", gap: ".3rem" }}>
                                    <strong style={{ color: "#03307d" }}> {day.format('ddd').toUpperCase()}</strong>
                                    <strong style={{ color: "#03307d" }}>HRS</strong>
                                    {filtrarDia(quitarAcentos(day.format('dddd')), day.format('YYYY-MM-DD'), horariosCal)}
                                </div>
                            ))}
                        </div>
                    </div>
                </Grow>
            </div>
        </div >
    );
};

export default SliderDias;
